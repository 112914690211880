import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ReactMarkdown from "react-markdown"
import Img from "gatsby-image"
import { NewsUnitHeader } from '../components/news'

const NewsUnitTemplate = ({ data }) => {

  return (
    <Layout>
      <SEO title="News" />
      <div className="mgt-medium news-unit page-content column">
        <NewsUnitHeader newsUnit={data.strapiArticle} />
        <Img fluid={data.strapiArticle.image.childImageSharp.fluid} />
        <ReactMarkdown
          transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.GATSBY_API_URL_PUBLIC}${uri}`}
          source={data.strapiArticle.content} />
      </div>
    </Layout>
  )
}
export default NewsUnitTemplate
export const query = graphql`  
  query NewsUnitTemplate($id: String!) {
    strapiArticle(id: {eq: $id}) {
        content
        author
        title
        creationTime
        articles_category {
          name
          slug
        }  
        article_tags{
          name
          slug
        } 
        image {
          childImageSharp {
            fluid(quality:100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        articles_category {
          name
        } 
    }
  }
`